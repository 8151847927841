import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"

import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">駐村編輯</h2>
          <h1 className="main_title">民眾共編</h1>
          <h6 className="speaker">策展人/林書豪</h6>
        </div>

        <div className="page_content">

          <section className="row_area_s project_c_3_a">
            <div className="column_4">
              <div className="context">
                <h3>你也有關於台北的記憶嗎?</h3>
                <p>駐村編輯是一個概念，目的在讓群眾找到支點，重新組合並且感知生活的樣子，就如同下圖以萬華為例，由眾人的意志所組成，你也有關於台北這座美麗城市的記憶嗎?</p>
                <p>食物的、工作的又或者娛樂的。</p>
                <h4>你所不知道的萬華，由你來說！</h4>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_3_b">
            <div className="column_6 onset_left_1 dialog_wrap">
              <div className="dialog_item"></div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>一肥仔麵店是有時日常午餐的選擇。</p>
                </div>
                <div className="item_person size_b">
                  <img src={require("../images/project_c/c_3/project_c_3_1.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>新富町市場根本我家廚房啦!<br />這間(指金禾壽司)，我最喜歡！!</p>
                </div>
                <div className="item_person">
                  <img src={require("../images/project_c/c_3/project_c_3_2.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>那個…珍味老店珍珠餛飩雖然小小顆，但是吃起來還不錯啦!偶爾會去吃。</p>
                </div>
                <div className="item_person">
                  <img src={require("../images/project_c/c_3/project_c_3_3.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>菓實日的甜點又美又有故事~<br />(但現已不做內用</p>
                </div>
                <div className="item_person size_b">
                  <img src={require("../images/project_c/c_3/project_c_3_4.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>有一間越南媽媽開的越式手作料理，叫做『越窩越好』但要去吃要早點去，因為老闆娘要顧小孩，都早早就關門了。</p>
                </div>
                <div className="item_person">
                  <img src={require("../images/project_c/c_3/project_c_3_5.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>啊!濟善老麵啊!那個柚子的層次 Perfect! 還有烏骨雞口味的，但我還是鍾愛柚子!</p>
                </div>
                <div className="item_person size_b">
                  <img src={require("../images/project_c/c_3/project_c_3_6.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>三味食堂的巨無霸鮭魚握壽司，每次去都要排隊，但吃到就銷魂!!</p>
                </div>
                <div className="item_person">
                  <img src={require("../images/project_c/c_3/project_c_3_7.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>那我要推悟州街2巷附近的燒麻糬和花生湯，這才是正港好吃的!!</p>
                </div>
                <div className="item_person">
                  <img src={require("../images/project_c/c_3/project_c_3_8.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>三水食品花生行的蜜餞，最推紅心芭樂。(但其實都好吃啦)!</p>
                </div>
                <div className="item_person">
                  <img src={require("../images/project_c/c_3/project_c_3_9.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>萬華火車站出來那間花枝羹(金好吃純正花枝羹)，不吃會後悔。</p>
                </div>
                <div className="item_person size_b">
                  <img src={require("../images/project_c/c_3/project_c_3_10.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>我剛剛買的是銀河咖啡的鮮奶茶，是可愛的年輕小店。</p>
                </div>
                <div className="item_person">
                  <img src={require("../images/project_c/c_3/project_c_3_11.png")} alt=""/>
                </div>
              </div>
            </div>
          </section>

        </div>


        <div className="page_footer">
          <Link to="/project_c_4" className="next_page">
            <div className="next_title">走讀/藝術共創工作坊</div>
            <div className="next_owner">策展人/林書豪</div>
          </Link>
        </div>

      </div>

    );
  }

}


export default Page
